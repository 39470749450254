<template>
  <div class="page-index" :style="{ width: width }">
    <a-radio-group name="radioGroup" v-model:value="checked" class="container">
      <a-radio value="1" class="item">本人</a-radio>
      <a-radio value="2" class="item">本部门</a-radio>
      <a-radio value="3" class="item">本部门及下属部门</a-radio>
      <a-radio value="4" class="item">所在公司及下属部门</a-radio>
      <a-radio value="5" class="item">所在公司及下属公司和部门</a-radio>
      <a-radio value="6" class="item">全部</a-radio>
    </a-radio-group>

    <div class="bottom-box">
      <a-radio-group name="radioGroup" value="1" class="set-box">
        <a-radio value="1" class="item">
          <template v-if="!defaultDataPermissions.length">自定义部门</template>
          <template v-else>
            <span style="max-width: 700px;">
              {{ checkNames }}
            </span>
            <!-- <div>
              <span v-for="(it, idx) in defaultDataPermissions" :key="it">
                {{ it.dataName }} <span v-if="idx != defaultDataPermissions.length - 1">、</span>
              </span>
            </div> -->
          </template>
        </a-radio>
      </a-radio-group>
      <div class="set-btn" @click="openCheckModal">{{ defaultDataPermissions.length ? '修改' : '设置' }}</div>
    </div>
  </div>

  <a-modal
    :visible="modalShow"
    centered
    title="自定义部门"
    width="655px"
    class="global-range-modal"
    @ok="submitModel"
    @cancel="closeModal"
  >
    <GlobalSelector
      :visible="modalShow"
      :selectorInfo="selectorInfo"
      :renderList="renderList"
      :selectedList="defaultDataPermissions"
      @getSearchInfo="getSearchInfo"
      @toNext="toNext"
      ref="globalSelector"
    />
  </a-modal>
</template>

<script>
import { reactive, toRefs, ref, computed, watch } from 'vue'
import { formateListData } from '@/utils/selectorListFormate.js'
import { getDepartmentOrStaff } from '@/apis/businessManage'

import GlobalSelector from '@/components/GlobalSelector/index.vue'
export default {
  components: {
    GlobalSelector
  },
  props: {
    width: {
      type: String
    },
    visible: {
      type: Boolean,
      value: false
    },
    compDetail: {
      type: Object,
      value: {}
    }
  },
  setup(props, { expose }) {
    const state = reactive({
      checked: '1',
      modalShow: false,
      selectorInfo: {
        placeholder: '请输入部门名称查询',
        abelCheckAll: true, // [是否显示全选]
        ableSelectDep: true, //[能否选择部门]
        isShowBreadcrumb: true, //[是否显示面包屑]
        isSelectDept: true, //[选择部门下的人还是选择部门]
        isSelectAllDept: true, // [是否支持部门全选，仅支持部门当前层级]
        customDefaultBread: [{ id: -1, label: '集团' }]
      },
      renderList: [], // 弹窗左侧数据
      defaultDataPermissions: [] // 自定义数据
    })

    const checkList = {
      '1': '本人',
      '2': '本部门',
      '3': '本部门及下属部门',
      '4': '所在公司及下属部门',
      '5': '所在公司及下属公司和部门',
      '6': '全部'
    }

    const checkNames = computed(() => {
      let str = ''
      state.defaultDataPermissions.forEach((it, idx) => {
        str += it.dataName
        if (idx != state.defaultDataPermissions.length - 1) {
          str += '、'
        }
      })
      return str
    })

    // 选中数据
    const getCheckState = () => {
      let text
      let list = [{ dataName: checkList[state.checked] }, ...state.defaultDataPermissions]
      list.forEach((it, idx) => {
        text += it.dataName
        if (idx != list.length - 1) {
          text += '、'
        }
      })
      return {
        checked: state.checked,
        defaultDataPermissions: state.defaultDataPermissions,
        showList: [{ dataName: checkList[state.checked] }, ...state.defaultDataPermissions],
        ellipsisText: text
      }
    }

    expose({
      getCheckState
    })

    // 打开弹窗
    const openCheckModal = () => {
      getScopeList({ departmentId: -1 })
      state.modalShow = true
    }

    const globalSelector = ref(null)

    // 弹窗取消
    const closeModal = () => {
      state.modalShow = false
      globalSelector.value.cancelEvent()
    }

    // 弹窗确定
    const submitModel = () => {
      let it
      if (!globalSelector.value.okEvent().length) {
        it = []
      } else {
        it = globalSelector.value.okEvent()
      }
      console.log('ittttttt', it)
      state.defaultDataPermissions = it
      closeModal()
    }

    //去下级
    const toNext = id => {
      let data = {
        departmentId: id
      }
      getScopeList(data)
    }
    //搜索
    const getSearchInfo = async e => {
      let data = {
        searchName: e,
        departmentId: -1
      }
      getScopeList(data)
    }
    //弹窗数据
    const getScopeList = async data => {
      let params = {
        selectorType: 2 //1部门人员2部门3员工
        // allData: true
      }
      const res = await getDepartmentOrStaff({ ...params, ...data })
      if (res.success) {
        // console.log('全部数据', res)
        state.renderList = formateListData(res)
        // console.log('处理后的renderList的值', state.renderList)
      }
    }

    watch(
      () => props.visible,
      newValue => {
        if (props.visible) {
          // console.log('ssssssssss',props.compDetail);
          state.defaultDataPermissions = props.compDetail.rangData?.defaultDataPermissions ?? []
          state.checked = props.compDetail.rangData?.checked ?? '1'
        }
      },
      {
        deep: true,
        immediate: true
      }
    )

    watch(
      () => props.compDetail,
      newValue => {
        console.log('监听父组件的值', newValue)
        console.log('有走这里吗', props.compDetail?.rangData)
        if (props.compDetail?.rangData) {
          console.log('ssssssssss', props.compDetail)
          state.defaultDataPermissions = props.compDetail.rangData.defaultDataPermissions
          state.checked = props.compDetail.rangData.checked
        } else {
          //批量设置
          state.defaultDataPermissions = []
          state.checked = '1'
        }
      },
      {
        deep: true,
        immediate: true
      }
    )

    return {
      ...toRefs(state),
      openCheckModal,
      submitModel,
      closeModal,
      toNext,
      getSearchInfo,
      globalSelector,
      checkNames
    }
  }
}
</script>

<style lang="less" scoped>
.page-index {
  // background-color: skyblue;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 0;
  border-bottom: 1px dashed #e8e8e8;
  padding-bottom: 16px;
  // border-bottom: 1px solid #E8E8E8;
}

.item {
  flex: 0 0 31.33%; /* 平均分配，一行展示3个 */
  box-sizing: border-box; /* 包含padding和border在内的宽度 */
  text-align: left;
}
.item:nth-child(3n) {
  margin-left: 18px;
}

.bottom-box {
  max-width: 770px;
  margin-top: 16px;
  display: flex;
  align-items: center;

  .set-btn {
    min-width: 28px;
    font-size: 14px;
    font-weight: 400;
    color: #0A7BFF;
    line-height: 20px;
    cursor: pointer;
  }
  :deep(.ant-radio-wrapper) {
    white-space: normal;
  }
}
</style>
